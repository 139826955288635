import { parents, initTouch } from "@formkit/drag-and-drop";

export const fixSentry = (parent) => {
  const parentData = parents.get(parent);

  if (!parentData) return;

  return {
    setup() {
      parentData.config.handleTouchstart = (eventData) => {
        const touchState = initTouch(eventData);
        touchState.touchedNodeDisplay = touchState.touchedNode.style.display;

        const rect = eventData.targetData.node.el.getBoundingClientRect();

        touchState.touchedNode.style.cssText = `
              width: ${rect.width}px;
              position: fixed;
              pointer-events: none;
              top: -9999px;
              z-index: 999999;
              display: none;
            `;

        document.body.append(touchState.touchedNode);

        //copyNodeStyle(eventData.targetData.node.el, touchState.touchedNode);

        touchState.touchedNode.style.display = "none";

        // document.addEventListener("contextmenu", preventDefault);
      };
    },
  };
};

export const fixhandleTouchstart = (eventData) => {
  const touchState = initTouch(eventData);
  touchState.touchedNodeDisplay = touchState.touchedNode.style.display;

  const rect = eventData.targetData.node.el.getBoundingClientRect();

  touchState.touchedNode.style.cssText = `
        width: ${rect.width}px;
        position: fixed;
        pointer-events: none;
        top: -9999px;
        z-index: 999999;
        display: none;
      `;

  document.body.append(touchState.touchedNode);

  //copyNodeStyle(eventData.targetData.node.el, touchState.touchedNode);

  touchState.touchedNode.style.display = "none";

  // document.addEventListener("contextmenu", preventDefault);
};
